import type { HeroType } from '../../Hero';

export const heroBackgroundFallback = (heroType: HeroType) => {
  if (heroType === 'DiscoverWeeklyHero') {
    return {
      image: {
        url: '/static/images/hero-backgrounds/paf-discover-weekly.jpg',
      },
      mobileImage: {
        url: '/static/images/hero-backgrounds/paf-discover-weekly-mobile.jpg',
      },
    };
  }

  if (heroType === 'GameItemHero') {
    return {
      image: {
        url: '/static/images/hero-backgrounds/paf-game-item.jpg',
      },
      mobileImage: {
        url: '/static/images/hero-backgrounds/paf-game-item-mobile.jpg',
      },
    };
  }

  return {
    image: {
      url: '/static/images/hero-backgrounds/paf-basic.svg',
    },
    mobileImage: {
      url: '/static/images/hero-backgrounds/paf-basic-mobile.svg',
    },
  };
};
